<script setup>
/******************************************************************************
 * PageComponent: ReadingsQueued
 *****************************************************************************/
import { computed, watch } from "vue";
import AppModal from "Components/Modal/AppModal.vue";
import LoadingSpinner from "Components/Loading/LoadingSpinner.vue";
import { useAppStore } from "Stores/app";
import { useReadingStore } from "Stores/reading";
import { useRouter } from "vue-router";
const appStore = useAppStore();
const data = useReadingStore();
const router = useRouter();
const isConnected = computed(() => appStore.online);
const emit = defineEmits(["close"]);
watch(isConnected, (newStatus, oldStatus) => {
    if (!oldStatus && newStatus && data.queuedReading) {
        data.submitReadings(router);
        emit("close");
    }
});
</script>

<template>
    <app-modal title="Sie sind offline.">
        <p>Wir haben Ihre Ablesungsdaten gespeichert.</p>
        <p>
            <strong
                >Sobald Sie wieder eine Internetverbindung haben, werden Ihre
                Daten automatisch übermittelt.</strong
            >
        </p>
        <loading-spinner size="128px" />
        <p>
            <strong class="warn"
                >Bitte schließen Sie dafür den Browser nicht, sonst erreichen
                uns Ihre Daten nicht.</strong
            >
        </p>
    </app-modal>
</template>

<style lang="scss" scoped>
p {
    margin: 0 0 get($sizes, "base") 0;

    &:last-of-type {
        margin: get($sizes, "base") 0 0 0;
    }

    .warn {
        font-size: #{get($sizes, "base") * 1.2};
    }
}

.loader {
    margin: 0 auto;
}

/* stylelint-disable selector-class-pattern */
.modal__body {
    overflow-y: hidden;
}
/* stylelint-enable selector-class-pattern */
</style>
